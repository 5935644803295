/* HANDLES 'loading...' PAGE BEFORE INIT */

html {
  background: #f6f6f6;
  color: #e4e4e4;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('/fonts/TitilliumWeb-ExtraLight.woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('/fonts/TitilliumWeb-Light.woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('/fonts/TitilliumWeb-Regular.woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('/fonts/TitilliumWeb-Bold.woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'Titillium Web';
  src: url('/fonts/TitilliumWeb-Black.woff2');
  font-weight: 900;
  font-style: normal;
} */
